import React from 'react'
import * as styles from './GlobalWrapper.module.scss'
import Header from './Header'
import Footer from './Footer'

const GlobalWrapper = props => {
  return (
    <div className={styles.globalWrapper}>
      <Header />
      <div className={styles.mainContents}>
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default GlobalWrapper
