import React from 'react'
import * as styles from './Footer.module.scss'
import { Link } from 'gatsby'

const Footer = () => {
  const toTheTopAnim = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <footer className={styles.footerComponent}>
      <div className={styles.toTheTopBtn} onClick={toTheTopAnim}>
        <i className={styles.toTheTopArrow}></i>
      </div>
      <div className={styles.footerMenuWrapper}>
        <ul className={styles.footerMenu}>
          <Link to="/" className={styles.footerMenuLink}>
            <li className={styles.footerMenuItem}>HOME</li>
          </Link>
          <Link to="/researchs" className={styles.footerMenuLink}>
            <li className={styles.footerMenuItem}>RESEARCHS</li>
          </Link>
          <Link to="/members" className={styles.footerMenuLink}>
            <li className={styles.footerMenuItem}>MEMBERS</li>
          </Link>
          <Link to="/news" className={styles.footerMenuLink}>
            <li className={styles.footerMenuItem}>NEWS</li>
          </Link>
          <Link
            to="https://forms.gle/LJPiB4tNXec1qZYA7"
            className={styles.footerMenuLink}
            target="_blank"
            rel="noopener"
          >
            <li className={styles.footerMenuItem}>CONTACT</li>
          </Link>
          <Link to="/credit" className={styles.footerMenuLink}>
            <li className={styles.footerMenuItem}>CREDIT</li>
          </Link>
        </ul>
        <p className={styles.copyRight}>©2021 YANAGI's LAB</p>
      </div>
    </footer>
  )
}

export default Footer
