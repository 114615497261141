import React from 'react'
import * as styles from './Header.module.scss'
import { Link } from 'gatsby'

const Header = () => {
  return (
    <header>
      <ul className={styles.globalMenu}>
        <li>
          <Link to="/" className={styles.globalMenuLink}>
            HOME
          </Link>
        </li>
        <li>
          <Link to="/researchs" className={styles.globalMenuLink}>
            RESEARCHS
          </Link>
        </li>
        <li>
          <Link to="/members" className={styles.globalMenuLink}>
            MEMBERS
          </Link>
        </li>
        <li>
          <Link to="/news" className={styles.globalMenuLink}>
            NEWS
          </Link>
        </li>
      </ul>
    </header>
  )
}

export default Header
