import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import faviconImgSrc from '../images/favicon.ico'
import ogImage from '../images/ogp.png'

const SEO = ({ description, image, title }) => {
  const { pathname } = useLocation()
  const defaultMetaData = {
    description:
      'これは公立はこだて未来大学における柳研究室20年間の歩みをまとめたアーカイブサイトです。',
    image: ogImage,
    siteName: "YANAGI's LAB Web Archive",
    type: 'website',
    url: 'yanagilab-archive.com',
  }
  const seo = {
    description: description || defaultMetaData.description,
    image: `${defaultMetaData.url}${image || defaultMetaData.image}`,
    site_name: defaultMetaData.siteName,
    title: title
      ? `${title} | ${defaultMetaData.siteName}`
      : defaultMetaData.siteName,
    type: defaultMetaData.type,
    url: `${defaultMetaData.url}${pathname}`,
  }
  return (
    <Helmet title={seo.title}>
      <html lang="en" />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={seo.url} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:title" content={seo.title} />
      <link rel="icon" href={faviconImgSrc} />
    </Helmet>
  )
}

export default SEO
